import React, { useCallback, useId, useState } from 'react';
import ReactSelect, {
  MultiValue,
  SingleValue,
  StylesConfig,
} from 'react-select';
import { IOptionType, ISelectCityProps } from './types';
import { functions, useApp } from '@wap-client/core';
import Icon from '@/components/base/icon';

const SelectCity: React.FC<ISelectCityProps> = ({
  selectedOption,
  setSelectedOption,
  options = [],
  disabled = false,
  isGetInpiredSelect = false,
}) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
  const app = useApp();

  const customStyles: StylesConfig<IOptionType> = {
    control: (styles) => ({
      ...styles,
      minHeight: '38px',
      minWidth: '54px',
      border: `1px solid #f0f0f0`,
      borderRadius: '100px',
      fontSize: '18px',
      fontWeight: '500',
      color: '#000',
      padding: '2px 24px 2px 16px',
      backgroundColor: selectedOption
        ? app.theme.colors.primary['75']
        : 'var(--LN0)',
      cursor: 'pointer',
      ':hover': {
        border: `1px solid ${app.theme.colors.primary['75']}`,
      },
      outline: 'none',
      boxShadow: 'none',
    }),

    placeholder: (styles) => ({
      ...styles,
      color: '#000',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1,
    }),
    input: (styles) => ({
      ...styles,
      margin: 0,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1,
      color: selectedOption ? '#ffffff' : '#7c7c7c',
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 10,
      minWidth: '144px',
      maxHeight: '240px',
    }),
    menuList: (styles) => ({
      ...styles,
      padding: 0,
    }),
    option: (styles, { isSelected, isFocused }) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '14px',
      padding: '10px 12px',
      cursor: isSelected ? 'auto' : 'pointer',
      backgroundColor: isSelected
        ? app.theme.colors.primary['75']
        : isFocused
        ? app.theme.colors.primary['200']
        : 'transparent',

      ':hover': {
        backgroundColor: isSelected
          ? app.theme.colors.primary['75']
          : app.theme.colors.primary['200'],
      },
      color: isSelected ? 'white' : isFocused ? 'black' : 'black',
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: 1,
      color: selectedOption ? '#ffffff' : '#000000',
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      order: isGetInpiredSelect ? -1 : 'auto',
    }),
  };

  const handleChange = (
    newValue: SingleValue<IOptionType> | MultiValue<IOptionType> | null
  ) => {
    if (newValue && !Array.isArray(newValue)) {
      setSelectedOption(newValue as IOptionType);
    }
  };

  const handleMenuOpen = useCallback(() => {
    setIsOpenDropdown(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setIsOpenDropdown(false);
  }, []);

  // remove select arrow down icon
  const DropdownIndicator = () => null;

  const IndicatorSeparator = () => {
    return (
      <Icon
        name={
          !isGetInpiredSelect
            ? 'icon-arrow-right-outline'
            : 'icon-menu-circle-outline'
        }
        className={functions.classnames(
          'select-input-arrow-icon',
          isOpenDropdown && 'open-dropdown',
          selectedOption && 'selected'
        )}
      />
    );
  };

  return (
    <ReactSelect
      className={functions.classnames(
        'select-city',
        isGetInpiredSelect && 'get-inspired'
      )}
      placeholder={app.settings.translations['cities']}
      options={options}
      styles={customStyles}
      components={{
        DropdownIndicator,
        IndicatorSeparator,
      }}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      value={selectedOption}
      onChange={handleChange}
      isMulti={false}
      isDisabled={disabled}
      instanceId={useId()}
    />
  );
};

export default SelectCity;
