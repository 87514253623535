import React, { useEffect, useRef, useState } from 'react';
import { IBuildRoutesCategory, IBuildRoutesProps } from './types';
import { Container } from '@/components/base/gridview';
import useDeviceType from '@/hooks/useDeviceType';
import { kebabToTitleCase, stringToKebabCase } from '@/utils';
import Card from '../card';
import Anchor from '@/components/base/anchor/Anchor';
import Icon from '@/components/base/icon';
import { functions } from '@wap-client/core';
import BuildRoutesCategoryTab from './build-routes-category-tab';
import SelectCity from './select-city';
import { IOptionType } from './select-city/types';
import {
  motion,
  useInView,
  useScroll,
  useSpring,
  useTransform,
} from 'framer-motion';

// const animations = {
//   container: {
//     hidden: {
//       opacity: 0,
//     },
//     show: {
//       opacity: 1,
//       transition: {
//         duration: 0.5,
//         delayChildren: 0.3,
//         staggerChildren: 0.15,
//         ease: [0.25, 1, 0.5, 1],
//       },
//     },
//   },
//   item: {
//     hidden: {
//       opacity: 0,
//       y: -100,
//     },
//     show: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         duration: 0.3,
//         ease: [0.25, 1, 0.5, 1],
//       },
//     },
//   },
//   title: {
//     hidden: {
//       opacity: 0,
//       scale: 0.6,
//     },
//     show: {
//       opacity: 1,
//       scale: 1,
//       transition: {
//         type: 'spring',
//         stiffness: 400,
//         damping: 40,
//         duration: 0.3,
//       },
//     },
//   },
//   tab: {
//     hidden: {
//       opacity: 0,
//       scale: 0,
//       x: -50,
//     },
//     show: {
//       opacity: 1,
//       scale: 1,
//       x: 0,
//       transition: {
//         duration: 0.3,
//         ease: [0.25, 1, 0.5, 1],
//       },
//     },
//   },
// };

const draw = {
  hidden: {
    pathLength: 0,
    opacity: 0,
  },
  visible: (i: number) => {
    const delay = 1 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

const BuildRoutes: React.FC<IBuildRoutesProps> = ({
  title,
  descriptions = [],
  link,
  data = [],
  like = false,
  tabAlign = 'left',
  isGetInspired = false,
  inspiredDatas,
  showCities = false,
  mbDesktop = 100,
  mtDesktop = 100,
  mtLaptop = 100,
  mbLaptop = 100,
  mbMobile = 50,
  mtMobile = 50,
}) => {
  const path1ContainerRef = useRef<HTMLDivElement>(null);
  const [activeCategoryId, setActiveCategoryId] = useState<string>('');
  const [activeCategoryData, setActiveCategoryData] = useState<
    IBuildRoutesCategory['categories']
  >([]);
  const [selectedCity, setSelectedCity] = useState<IOptionType | null>();
  const deviceType = useDeviceType();
  const isInView = useInView(path1ContainerRef, { once: true });
  const { scrollYProgress } = useScroll({
    layoutEffect: false,
    target: path1ContainerRef,
    offset: ['-400px', '0px'],
  });
  const yRange = useTransform(scrollYProgress, [0.7, 0], [1, 0]);
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });

  const options: Array<IOptionType> = [
    { value: 'istanbul', label: 'İstanbul' },
    { value: 'antalya', label: 'Antalya' },
    { value: 'izmir', label: 'İzmir' },
    { value: 'muğla', label: 'Muğla' },
  ];

  const onChangeTab = (id: string) => {
    if (data.length === 0) {
      return;
    }

    const title = kebabToTitleCase(id);

    const category = data.find((category) => category?.categoryTitle === title);
    if (category?.categoryTitle) {
      setActiveCategoryId(stringToKebabCase(id));
    }
  };

  const renderCards = () => {
    // kategorili bir yapı mı yoksa kategori başlıkları olmadan sadece kartlar mı listelensin ?
    if (activeCategoryData && activeCategoryData.length > 0) {
      return activeCategoryData
        .slice(0, 3)
        .map((cardData, index) => (
          <Card key={index} {...cardData} like={like} />
        ));
    } else if (
      data.length >= 1 &&
      !data?.[0]?.categoryTitle &&
      data[0]?.categories
    ) {
      return data[0].categories
        .slice(0, 3)
        .map((cardData, index) => (
          <Card key={index} {...cardData} like={like} />
        ));
    }

    return null;
  };

  const renderBodyCategories = () => {
    return (
      <>
        <Container>
          <div className="categories">
            <div
              // variants={animations.container}
              className={functions.classnames(
                'categories-tabs',
                `categories-tabs-${tabAlign}`
              )}
            >
              {data.map((category, index) => {
                const id = stringToKebabCase(category.categoryTitle || '');
                if (category?.categoryTitle) {
                  return (
                    <BuildRoutesCategoryTab
                      key={index}
                      // motion={{ variants: animations.tab }}
                      id={id}
                      className={functions.classnames(
                        activeCategoryId === id && 'active'
                      )}
                      onClick={() => onChangeTab(id)}
                    >
                      {category.categoryTitle}
                    </BuildRoutesCategoryTab>
                  );
                }
                return null;
              })}
              {showCities && (
                <div
                // variants={animations.tab}
                >
                  <SelectCity
                    options={options}
                    selectedOption={selectedCity}
                    setSelectedOption={setSelectedCity}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
        <Container size="wide">
          <div className="cards">{renderCards()}</div>
        </Container>
      </>
    );
  };

  const renderGetInspired = () => {
    return (
      <>
        <Container>
          <div className="categories">
            <div
              // variants={animations.container}
              className={functions.classnames(
                'categories-tabs',
                `categories-tabs-${isGetInspired ? 'center' : tabAlign}`
              )}
            >
              <div
              // variants={animations.tab}
              >
                <SelectCity
                  options={[{ value: 'Get Inspired', label: 'Get Inspired' }]}
                  selectedOption={{
                    value: 'Get Inspired',
                    label: 'Get Inspired',
                  }}
                  setSelectedOption={setSelectedCity}
                  isGetInpiredSelect
                  disabled
                />
              </div>
              {showCities && (
                <div
                //  variants={animations.tab}
                >
                  <SelectCity
                    options={options}
                    selectedOption={selectedCity}
                    setSelectedOption={setSelectedCity}
                  />
                </div>
              )}
            </div>
          </div>
        </Container>
        <Container size="wide">
          <div className="cards">
            {inspiredDatas &&
              inspiredDatas.length > 0 &&
              inspiredDatas
                .slice(0, 3)
                .map((cardData, index) => (
                  <Card key={index} {...cardData} like={like} />
                ))}
          </div>
        </Container>
      </>
    );
  };

  useEffect(() => {
    if (isGetInspired && inspiredDatas && inspiredDatas?.length > 0) {
      setActiveCategoryData(inspiredDatas);
    }
  }, [isGetInspired]);

  useEffect(() => {
    if (activeCategoryId !== '') {
      const filteredData = data.find(
        (category) =>
          stringToKebabCase(category?.categoryTitle || '') === activeCategoryId
      );

      if (filteredData) {
        setActiveCategoryData(filteredData?.categories || []);
      }
    }
  }, [activeCategoryId, data]);

  useEffect(() => {
    if (activeCategoryId && selectedCity) {
      setSelectedCity(null);
    }
  }, [activeCategoryId]);

  useEffect(() => {
    if (selectedCity && activeCategoryId !== '') {
      setActiveCategoryId('');
    }
  }, [selectedCity]);

  useEffect(() => {
    if (activeCategoryId === '' && data.length > 0) {
      onChangeTab(data[0].categoryTitle || '');
    }
  }, [data]);

  if (data.length === 0) {
    return null;
  }

  return (
    <div
      className="build-routes"
      // variants={animations.container}
      // initial="hidden"
      // animate={`${isInView ? 'show' : 'hidden'}`}
      // ref={path1ContainerRef}
      // viewport={{ once: true, amount: 0.1 }}
      style={{
        marginTop:
          deviceType === 'mobile'
            ? mtMobile
            : deviceType === 'laptop' || deviceType === 'tablet'
            ? mtLaptop
            : mtDesktop,
        marginBottom:
          deviceType === 'mobile'
            ? mbMobile
            : deviceType === 'laptop' || deviceType === 'tablet'
            ? mbLaptop
            : mbDesktop,
      }}
    >
      <Container
        size={deviceType === 'mobile' ? 'wide' : 'full'}
        className="header"
      >
        <h3
          className="header-title"
          // variants={animations.title}
        >
          {title}
        </h3>
        <div
          // variants={animations.item}
          className="header-descriptions"
        >
          {descriptions.map((desc, index) => (
            <span key={index} className="header-descriptions-item">
              {desc?.text}
            </span>
          ))}
        </div>

        <div ref={path1ContainerRef} className="path title-path-p1">
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 848.95 512.312"
          >
            <motion.path
              d="M-9966.875,1617.573s-193.87-93.609-341.854,117.62-443.838,201.615-443.838,201.615"
              transform="matrix(0.985, 0.174, -0.174, 0.985, 10929.667, 314.336)"
              fill="none"
              stroke="#1980dd"
              strokeLinejoin="round"
              strokeWidth="40"
              style={{
                pathLength,
              }}
            />
          </motion.svg>
        </div>
        <div className="path title-path-p2">
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 764.396 222.112"
          >
            <motion.path
              d="M-9314,1814.4s185.474,178.151,371.9,187.349,364.328-173.062,364.328-173.062"
              transform="translate(9327.854 -1799.972)"
              fill="none"
              strokeLinejoin="round"
              strokeWidth="40"
              style={{
                pathLength,
              }}
            />
          </motion.svg>
        </div>
        <div className="path title-path-p3">
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 744.487 741.821"
          >
            <motion.path
              d="M47.8,91.435a85.229,85.229,0,0,1-44.153-11.8A88.752,88.752,0,0,1-28.147,47.839a85.286,85.286,0,0,1-11.8-44.181,84.572,84.572,0,0,1,11.8-44.026A89.05,89.05,0,0,1,3.645-72.033,85.241,85.241,0,0,1,47.8-83.835a85.286,85.286,0,0,1,44.181,11.8,89.179,89.179,0,0,1,31.8,31.665A84.489,84.489,0,0,1,135.6,3.658a85.2,85.2,0,0,1-11.817,44.181,88.88,88.88,0,0,1-31.8,31.792A85.274,85.274,0,0,1,47.8,91.435Zm0-45.167q17.945,0,30.164-12.333T90.181,3.658q0-17.941-12.206-30.134T47.8-38.668A41.043,41.043,0,0,0,17.8-26.462Q5.471-14.257,5.471,3.658T17.817,33.923A40.818,40.818,0,0,0,47.8,46.268ZM24.334,99.641v-20.1H71.292v20.1q0,47.591,64.89,65.51l133.35,36.795q48.312,13.117,73.183,41.125t24.871,69.2V332.15H320.37V312.273q0-47.395-64.886-65.51l-133.354-36.8q-48.312-13.091-73.054-41.236T24.334,99.641ZM344.117,320.225a83.886,83.886,0,0,1,44.026,11.928,90.413,90.413,0,0,1,31.665,31.792,88.542,88.542,0,0,1,0,88.332,89.373,89.373,0,0,1-31.665,31.665,88.546,88.546,0,0,1-88.334,0,90.419,90.419,0,0,1-31.79-31.665,87.727,87.727,0,0,1,0-88.332,91.478,91.478,0,0,1,31.79-31.792A84.4,84.4,0,0,1,344.117,320.225Z"
              transform="matrix(0.695, -0.719, 0.719, 0.695, 88.057, 368.711)"
              style={{
                fill: pathLength, // Bunu kaldırabilirsiniz
                fillOpacity: pathLength, // Bunu ekleyebilirsiniz
              }}
            />
          </motion.svg>
        </div>
      </Container>
      <div className="body">
        {!isGetInspired ? renderBodyCategories() : renderGetInspired()}
        <Container size="wide">
          <div
            className="build-routes-footer"
            // variants={animations.item}
          >
            {link && link?.children && (
              <Anchor {...link} className="route-link">
                <span>{link?.children}</span>
                <Icon name="icon-arrow-right" />
              </Anchor>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default BuildRoutes;
